import React, { useEffect, useState } from "react";
import { filterIsolatedBets } from "../../services/IsolatedBets/isolatedBets";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import AllBetsTrTotal from "../AllBetsTrTotal";

const PreviousHandTab = (props) => {

  const PAGE_LENGTH = 50;
  const [listLength, setListLength] = useState(50);
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);

  const urlParams = new URLSearchParams(window.location.search);
  const sort = urlParams.get("sort");
  
  window.setPreviousBetsTabLang = setLanguage;

  const [previousBets, setPrevBets] = useState({});

  useEffect(() => {
    setPrevBets(props.bets)
  },[]);

  return (
    <>
      <table className="bets-table bets-table-total">
        <thead>
          <tr className={"table-header " + (rocketmanTransport.r7css ? "r7css" : "")}>
            <th>{translate('user')}</th>
            <th>{translate('bet_bets_tab')}</th>
            <th>{translate('coeff')}</th>
            <th>{translate('win')}</th>
            {rocketmanTransport.isChatVisible ? <th className="share"></th> : null}
          </tr>
        </thead>
        <tbody>
        {sort == 'true' ? Object.values(previousBets).slice(0,listLength).sort((a,b) => Number(b.bet) - Number(a.bet)).map((bet,index) => {
          return (
            filterIsolatedBets(bet) ? (
              <AllBetsTrTotal
                key={index}
                avatar={`avatar${bet.avatar_id}`}
                username={bet.username}
                flagCode={bet.country_code == 'XK' && language === 'sr' ? 'rs' : bet.country_code}
                bet={bet.bet}
                betEur={bet.bet_eur}
                coefficient={bet.win_eur && bet.bet_eur > 0 ? Number(bet.win_eur) / Number(bet.bet_eur) : Number(bet.win) / Number(bet.bet)}
                win={bet.win ? bet.win : ""}
                winEur={bet.win_eur ? bet.win_eur : ""}
                betCurrency={bet.currency_code}
                valute={rocketmanTransport.myCurrency}
                round={bet.round_id}
                isVip={bet.avatar_id > 49 ? true : false}
              />) : 
              null
          )
        }) : Object.values(previousBets).slice(0,listLength).map((bet,index) => {
          return (
            filterIsolatedBets(bet) ? (
              <AllBetsTrTotal
                key={index}
                avatar={`avatar${bet.avatar_id}`}
                username={bet.username}
                flagCode={bet.country_code == 'XK' && language === 'sr' ? 'rs' : bet.country_code}
                bet={bet.bet}
                betEur={bet.bet_eur}
                coefficient={bet.win_eur && bet.bet_eur > 0 ? Number(bet.win_eur) / Number(bet.bet_eur) : Number(bet.win) / Number(bet.bet)}
                win={bet.win ? bet.win : ""}
                winEur={bet.win_eur ? bet.win_eur : ""}
                betCurrency={bet.currency_code}
                valute={rocketmanTransport.myCurrency}
                round={bet.round_id}
                isVip={bet.avatar_id > 49 ? true : false}
              />) : 
              null
          )
        })}
        </tbody>
      </table>
      {listLength < Object.keys(props.bets).length && (
        <div className="load-more-button-holder">
          <button
            className={"primary-button " + (rocketmanTransport.r7css ? "r7css" : "")}
            onClick={() =>
              setListLength((oldListLength) => oldListLength + PAGE_LENGTH)
            }
          >
            LOAD MORE
          </button>
        </div>
      )}
    </>
  );
};

export default PreviousHandTab;
