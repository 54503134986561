import React, { useEffect, useState } from "react";
import ButtonNavigator from "../components/main/ButtonNavigator";
import SecondaryButton from "../components/SecondaryButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import MainTitle from "../components/MainTitle";
import ScrollAnimation from "react-animate-on-scroll";
import DesktopPageBackButton from "../components/DesktopPageBackButton";
import translate from "../services/Translation/lang";
import rocketmanTransport from "../services/RocketmanTransport";
import { useHistory } from "react-router-dom";
import { currencyFormatter } from "../services/Helpers/NumberFormatter";
import Currency from "../components/Currency/Currency";
import AlienSaucerFreeBetsImage from "../img/alien-saucer-free-bets.png";
import AstronautPromoCreditImage from "../img/astronaut-promo-credit.png";
import PrimaryButton from "../components/PrimaryButton";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

export default function DetailedRules() {
  const [isPlaying, setIsPlaying] = useState(true);
  const [language, setLanguage] = useState(rocketmanTransport.launcherLanguage);
  const [showCurrency, setShowCurrency] = useState(
    rocketmanTransport.isCurrencyVisible
  );
  const history = useHistory();
  useEffect(() => {
    if (language === "rs") {
      setLanguage("sr");
    }
    let sound = false;
    let music = false;
    if (isPlaying) {
      if (rocketmanTransport.tempSound) {
        sound = true;
      }
      if (rocketmanTransport.tempMusic) {
        music = true;
      }
    }
    rocketmanTransport.toggleVideoSoundAndMusic(sound, music);
  }, [isPlaying, language]);

  useEffect(() => {
    setShowCurrency(rocketmanTransport.isCurrencyVisible);
  }, [rocketmanTransport.isCurrencyVisible]);

  const OFFSET = 74;
  return (
    <div className="page-holder with-bottom-navigator detailed-rulles-page">
      <ScrollToTopOnMount />
      <div className="container">
        <div className="go-to-home" onClick={() => history.replace("/")}></div>
        <div className="desktop-page-holder">
          <ScrollAnimation animateOnce offset={OFFSET} animateIn="fadeInUp">
            <div className="desktop-page-back">
              <MainTitle title={translate("detailed_game_rules")} />
              <DesktopPageBackButton />
            </div>
            <p className="mb-3 text-justify">
              {translate("welcome_to_rocketman")}
            </p>
            <hr className="desktop-separator-line"></hr>
          </ScrollAnimation>
          <ScrollAnimation animateOnce offset={OFFSET} animateIn="fadeInUp">
            <MainTitle
              title={translate("general_rules")}
              className="mt-4 mb-3"
            />
          </ScrollAnimation>
          <ScrollAnimation animateOnce offset={OFFSET} animateIn="fadeInUp">
            <p className="text-white mb-3 text-justify">
              {translate("multiplier_starts")} <br />
              {translate("warnings_are_calculated")}
              <br />
              {translate("if_the_maximum_multiplier")}
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateOnce offset={OFFSET} animateIn="fadeInUp">
            <MainTitle
              title={translate("auto_play_and_autocash")}
              className="mb-3"
            />
          </ScrollAnimation>
          <ScrollAnimation animateOnce offset={OFFSET} animateIn="fadeInUp">
            <p className="text-white mb-3 text-justify">
              {translate("game_r_1_paragraph")}
            </p>
            {!rocketmanTransport.singleBet ? <img
              src={
                showCurrency
                  ? `auto-bet&auto-cashout/auto-bet-${language}.png`
                  : `auto-bet&auto-cashout/auto-bet-en-currency-hidden.png`
              }
              alt="auto bet and auto cashout screenshot"
              className="mb-3 menu-center"
            /> : <img src="auto-bet&auto-cashout/auto-bet-single-bet-en.png" alt="auto-bet-single-bet-en.png" className="mb-3 menu-center" />}
            <p className="text-white mb-3 text-justify">
              {translate("game_r_2_paragraph")}
            </p>
          </ScrollAnimation>
          <MainTitle title={translate("shourtcut_keys")} className="mb-3" />
          <ScrollAnimation animateOnce offset={OFFSET} animateIn="fadeInUp">
            <p className="text-white mb-3 text-justify">
              {translate("game_r_3_paragraph")}
            </p>
            <table
              className="shortcut-table"
              style={{ color: "white", border: "1px solid white" }}
            >
              <thead>
                <tr>
                  <th>{translate("key")}</th>
                  <th>{translate("function")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>B</td>
                  <td>{translate("place_bet_with_last")}</td>
                </tr>
                <tr>
                  <td>C</td>
                  <td>{translate("cashout")}</td>
                </tr>
                <tr>
                  <td>ESC</td>
                  <td>{translate("exit_full_screen")}</td>
                </tr>
              </tbody>
            </table>
          </ScrollAnimation>
          {!rocketmanTransport.removeJackpotRules ? <div>
            <MainTitle
              title={translate("progressive_jackpot")}
              className="mb-3 mt-3"
            />
            <ScrollAnimation
              animatePreScroll={false}
              animateOnce
              offset={OFFSET}
              animateIn="fadeInUp"
            >
              <p className="text-white mb-3 text-justify">
                {translate("game_r_4_paragraph")} <br />{" "}
              </p>
              <p className="text-white mb-3 text-justify">
                {/* {translate("game_r_5_paragraph")} <br /> */}
                {translate("game_r_6_paragraph")}
                <br />
                {translate("game_r_7_paragraph")}
              </p>
              <p className="text-white mb-5 text-justify">
                {translate("game_r_8_paragraph")} <br />{" "}
              </p>
              <p className="text-white mb-5 text-justify">
                {translate("game_r_8a_paragraph")} <br />{" "}
              </p>
            </ScrollAnimation>
          </div> : null}
          <ScrollAnimation
            animatePreScroll={false}
            animateOnce
            offset={OFFSET}
            animateIn="fadeInUp"
          >
            <div
              className={
                "promo-credits-main-holder mb-4 " +
                (language === "cs" ? "hidden" : "")
              }
            >
              <div className="promo-credits-inline-holder">
                <MainTitle
                  title={translate("promo_credits")}
                  className="mb-4"
                />
                <p className="text-white text-justify  mb-4">
                  {translate("game_r_9_paragraph")}
                </p>
                <p className="text-white text-justify  mb-4">
                  {translate("game_r_10_paragraph")}
                </p>
                <p className="text-white text-justify  mb-4">
                  {translate("game_r_11_paragraph")}
                </p>
                <p className="text-white text-justify  mb-4">
                  {translate("game_r_12_paragraph")}
                </p>
              </div>
              <div className="promo-credits-image">
                {/* <img
                  src={
                    showCurrency
                      ? `promo-credits/promo-credits-${language}.png`
                      : `promo-credits/promo-credits-xe.png`
                  }
                  alt="promo_credits_screenshot"
                /> */}
                <div className="astronaut-pop-up-body">
                  <img
                    src={AstronautPromoCreditImage}
                    alt="astronaut-promo-credit"
                  />
                  <div className="pop-up-promo-text">
                    <div className="pop-up-promo-text-holder">
                      <h1 className="pupt-vissible">
                        {translate("austronaut_promo_credit")}
                      </h1>
                      <h1 className="pupt-hidden">
                        {translate("austronaut_promo_credit")}
                      </h1>
                    </div>
                  </div>
                  <div className="pop-up-message-box">
                    <div className="pop-up-promo-bonus">
                      <div className="pop-up-promo-bonus-holder">
                        <h1 className="pupb-vissible">
                          +{currencyFormatter(rocketmanTransport.minBet * 10)}
                          <Currency>{rocketmanTransport.myCurrency}</Currency>
                        </h1>
                        <h1 className="pupb-hidden">
                          +{currencyFormatter(rocketmanTransport.minBet * 10)}
                          <Currency>{rocketmanTransport.myCurrency}</Currency>
                        </h1>
                      </div>
                    </div>
                    <p className="pop-up-message-description">
                      {translate("promo_credit_condradulations")}
                    </p>
                  </div>
                  <div className="pop-up-close">
                    <PrimaryButton
                      title={translate("ok_thanks")}
                      onClick={() => {}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollAnimation>

          <ScrollAnimation
            animatePreScroll={false}
            animateOnce
            offset={OFFSET}
            animateIn="fadeInUp"
            className="mb-5"
          >
            <div className={"promo-credits-main-holder reversed mb-4 "}>
              <div className="promo-credits-inline-holder">
                <MainTitle title={translate("free_bets")} className="mb-4" />
                <p className="text-white text-justify  mb-4">
                  {translate("game_r_13_paragraph")}
                </p>
                <p className="text-white text-justify  mb-4">
                  {translate("game_r_14_paragraph")}
                </p>
                <p className="text-white text-justify  mb-4">
                  {translate("game_r_15_paragraph")}
                </p>
              </div>
              <div className="promo-credits-image">
                {/* <img src={showCurrency ? `free-bets/free-bets-${(language)}.png` : `free-bets/free-bets-xe.png`} alt="free_bets_screenshot" /> */}
                <div className="astronaut-pop-up-body">
                  <img
                    src={AlienSaucerFreeBetsImage}
                    alt="astronaut-promo-credit"
                  />
                  <div className="pop-up-promo-text alien-saucer-text">
                    <div className="pop-up-promo-text-holder">
                      <h1 className="pupt-vissible">
                        {translate("alien_saucer_free_bets")}
                      </h1>
                      <h1 className="pupt-hidden">
                        {translate("alien_saucer_free_bets")}
                      </h1>
                    </div>
                  </div>
                  <div className="pop-up-message-box">
                    <div className="pop-up-promo-bonus">
                      <div className="pop-up-promo-bonus-holder">
                        <h1 className="pupb-vissible">
                          +10 {translate("at")}{" "}
                          {currencyFormatter(rocketmanTransport.minBet)}{" "}
                          <Currency>{rocketmanTransport.myCurrency}</Currency>
                        </h1>
                        <h1 className="pupb-hidden">
                          +10 {translate("at")}{" "}
                          {currencyFormatter(rocketmanTransport.minBet)}{" "}
                          <Currency>{rocketmanTransport.myCurrency}</Currency>
                        </h1>
                      </div>
                    </div>
                    <p className="pop-up-message-description">
                      {translate("congratulations_you_have_won_free_bets")} 10{" "}
                      <Currency>{rocketmanTransport.myCurrency}</Currency>{" "}
                      {translate("at")}{" "}
                      {currencyFormatter(rocketmanTransport.minBet)}{" "}
                      {translate("game_round")}
                    </p>
                  </div>
                  <div className="pop-up-close">
                    <PrimaryButton
                      title={translate("ok_thanks")}
                      onClick={() => {}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </ScrollAnimation>
          
          {rocketmanTransport.meteor_shower_active ? (<div>
          <MainTitle
                title={translate("meteor_shower")}
                className="mb-3 mt-3 text-uppercase"
              />
              <ScrollAnimation
                animatePreScroll={false}
                animateOnce
                offset={OFFSET}
                animateIn="fadeInUp"
              >
                <p className="text-white mb-3 text-justify">
                {translate("game_meteor_shower_paragraph")}
                </p>
              </ScrollAnimation>
          </div>) : null}
          {language === "cs" ? null : (
            <div>
              <MainTitle
                title={translate("return_to_player")}
                className="mb-3 mt-3"
              />
              <ScrollAnimation
                animatePreScroll={false}
                animateOnce
                offset={OFFSET}
                animateIn="fadeInUp"
              >
                <p className="text-white mb-3 text-justify">
                  {translate("game_r_16a_paragraph")}{" "}
                  <b>{rocketmanTransport.rocketmanRTP + 2}%</b>{" "}
                  {translate("game_r_16b_paragraph")}{" "}
                  {rocketmanTransport.rocketmanRTP}%{" "}
                  {translate("game_r_16c_paragraph")}
                </p>
                <p className="text-white mb-3 text-justify">
                  {translate("game_r_17_paragraph")}
                </p>
              </ScrollAnimation>
            </div>
          )}
          {rocketmanTransport.useInsurance ? (<><MainTitle
            title={translate("insurance")}
            className="mb-3 mt-3 "
          />
          <ScrollAnimation
            animatePreScroll={false}
            animateOnce
            offset={OFFSET}
            animateIn="fadeInUp"
          >
            <p className="text-white mb-3">
              {translate("insurance_paragraph")}
            </p>
          </ScrollAnimation></>) : null}
          <MainTitle
            title={translate("dissconnection_policy")}
            className="mb-3 mt-3 "
          />
          <ScrollAnimation
            animatePreScroll={false}
            animateOnce
            offset={OFFSET}
            animateIn="fadeInUp"
          >
            <p className="text-white mb-3">
              {translate("game_r_18_paragraph")}
            </p>
          </ScrollAnimation>
          <MainTitle
            title={translate("network_latency")}
            className="mb-3 mt-3 "
          />
          <ScrollAnimation
            animatePreScroll={false}
            animateOnce
            offset={OFFSET}
            animateIn="fadeInUp"
          >
            <p className="text-white mb-3">
              {translate("game_r_24_paragraph")}
            </p>
          </ScrollAnimation>
          {rocketmanTransport.isChatVisible ? (
            <div>
              <MainTitle title={translate("chat")} className="mb-3 mt-3" />
              <ScrollAnimation
                animatePreScroll={false}
                animateOnce
                offset={OFFSET}
                animateIn="fadeInUp"
              >
                <p className="text-white mb-3">
                  {translate("game_r_19_paragraph")}
                </p>
                <p className="text-white mb-3">
                  {translate("game_r_20_paragraph")}
                </p>
              </ScrollAnimation>
            </div>
          ) : null}
          <MainTitle title={translate("sound")} className="mb-3 mt-3" />
          <ScrollAnimation
            animatePreScroll={false}
            animateOnce
            offset={OFFSET}
            animateIn="fadeInUp"
          >
            <p className="text-white mb-3">
              {translate("game_r_21_paragraph")}
            </p>
            <img
              src={`sound-menu/sound-menu-${
                language === "xe" ? "en" : language
              }.png`}
              alt="sound_menu_screenshot"
              className="mb-3 menu-center sound-menu-img"
            />
          </ScrollAnimation>
          <MainTitle title={translate("game_history")} className="mb-3 mt-3" />
          <ScrollAnimation
            animatePreScroll={false}
            animateOnce
            offset={OFFSET}
            animateIn="fadeInUp"
          >
            <p className="text-white mb-3 text-justify">
              {translate("game_r_22_paragraph")}
            </p>
          </ScrollAnimation>
          <MainTitle
            title={translate("error_handling")}
            className="mb-3 mt-3"
          />
          <ScrollAnimation
            animatePreScroll={false}
            animateOnce
            offset={OFFSET}
            animateIn="fadeInUp"
          >
            <p className="text-white mb-3 text-justify">
              {translate("game_r_23_paragraph")}
            </p>
          </ScrollAnimation>
          <ScrollAnimation
            animatePreScroll={false}
            animateOnce
            offset={OFFSET}
            animateIn="fadeInUp"
          >
            <p className="malfunction-details text-justify">
              {translate("malfunction_voids")}{" "}
              {language === "cs" ? null : (
                <span>
                  {currencyFormatter(rocketmanTransport.maxWin)}{" "}
                  <Currency>{rocketmanTransport.myCurrency}</Currency>{" "}
                  {translate("per_bet")}
                </span>
              )}
            </p>
          </ScrollAnimation>
        </div>
      </div>
      <ButtonNavigator className={rocketmanTransport.mobileCloseButton ? "closeButton" : ""}>
        <div className="container">
          <Row className="gutter-2">
            <Col>
              <Link className="menu-buttons" to="/">
                <SecondaryButton title={translate("close")} />
              </Link>
            </Col>
          </Row>
        </div>
      </ButtonNavigator>
    </div>
  );
}
