import React from "react";
import TableShareSvg from "../components/svg/table-share-svg";
import ProvablyFair from "../components/svg/provably-fair-svg";
import rocketmanTransport from "../services/RocketmanTransport";
import { useHistory } from "react-router-dom";
import { canShare, checkEachCanShare } from "../services/SpamFilter/TicketShareSpamFilter";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Currency from "./Currency/Currency";
import { currencyFormatter } from "../services/Helpers/NumberFormatter";

const MyBetsTr = (props) => {

  const urlParams = new URLSearchParams(window.location.search);
  const version = urlParams.get("version");
  const history = useHistory();
  const { width } = useWindowDimensions();
  
  const handleBetVerify = (roundId) => {
    if (version !== "desktop" || width < 1024) {
      history.replace({
        search: `?ticketRid=${roundId}&ticketTime=${props.date}`,
        pathname: '/bet-verify',
      });
    } else {
      window.setBetVerifyActive(true);
      window.setBetInfoActive(false);
      window.setTicketRid(roundId);
      window.setTicketTime(props.betTime);
    }
  };

  const highLight = (mp) => {
    if (Number(mp) > 2 && Number(mp) <= 9.99) return "win";
    if (Number(mp) > 9.99 && Number(mp) <= 99.99) return "win-big";
    if (Number(mp) > 99.99) return "win-legendary";

    return "win-small";
  }

  const shareTicket = () => {
    if (canShare(props.coefficient,props.winEur)) {
      const msgParams = {
        avatar: rocketmanTransport.avatarId,
        countryCode: rocketmanTransport.countryCode,
        username: rocketmanTransport.username,
        entry: "",
        toUser: "",
        cashedOut: props.coefficient.toFixed(2),
        winEur: props.winEur,
        betEur: props.betEur,
        winAmount: props.win,
        betAmount: props.bet,
        betCurrency: props.currency,
        round: props.round
      }

      const sharedPrefix =  "sharedBet-RCF2408-";
      const msg = sharedPrefix + JSON.stringify(msgParams);

      return rocketmanTransport.requestSendChatMessage(msg);
    }
  }

  return (
    <tr className={"all-bets-tr " + (rocketmanTransport.r7css ? "r7css" : "")}>
      <td>{props.date}</td>
      <td>{props.bet % 1 !== 0 ? currencyFormatter(Number(props.bet).toFixed(rocketmanTransport.noOfDecimals)) : currencyFormatter(Number(props.bet))} <Currency>{props?.valute}</Currency></td>
      <td>
        <span>
          {props.win ? (
            <span className={["all-bets-tr-coefficient", highLight(props.coefficient)].join(" ")}>{props.coefficient.toFixed(2)}x</span>
          ) : (
            <span> --- </span>
          )}
        </span>
      </td>
      <td>
        {props.win ? <><span>{props.win % 1 !== 0 ? currencyFormatter(Number(props.win).toFixed(rocketmanTransport.noOfDecimals)) : currencyFormatter(Number(props.win))} <Currency>{props?.valute}</Currency></span></> : <span> --- </span>}
      </td>
      {rocketmanTransport.isChatVisible ? <td>
        <div className="my-bets-share">
          <button className={["table-share", props.currRound <= props.round && 'disabled'].join(" ")}>
            <ProvablyFair width={14} height={17} onClick={() => props.currRound <= props.round ? () => null : handleBetVerify(props.round) } />
          </button>
          <button className={["table-share", !props.win || !checkEachCanShare(props.coefficient,props.winEur) ? "disabled" : "" ].join(" ")} onClick={() => props.win && checkEachCanShare(props.coefficient,props.winEur) ? shareTicket() : () => null}>
            <TableShareSvg />
          </button>
        </div>
      </td> : null}
    </tr>
  );
};

export default MyBetsTr;
