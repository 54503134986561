import moment from "moment";
import React, { useEffect, useState } from "react";
import BonusType from "../../services/BonousTypes";
import rocketmanTransport from "../../services/RocketmanTransport";
import translate from "../../services/Translation/lang";
import Currency from "../Currency/Currency";
import { currencyFormatter } from "../../services/Helpers/NumberFormatter";

export default function BonusingHistoryTable() {
  const [bonusingHistory, setBonusingHistory] = useState([]);

  useEffect(() => {
    rocketmanTransport.requestPlayerBonusHistory();
  }, []);

  window.setBonusingHistory = setBonusingHistory;

  return (
    <table className="bets-table my-bets-table bonusing-history-table">
      <thead>
        <tr className={"table-header " + (rocketmanTransport.r7css ? "r7css" : "")}>
          <th>{translate("date")}</th>
          <th>{translate("bonus_type")}</th>
          <th>{translate("win")}</th>
        </tr>
      </thead>
      <tbody>
        {bonusingHistory.map((bonus, index) => {
          return (
            <tr className={"all-bets-tr " + (rocketmanTransport.r7css ? "r7css" : "")} key={index}>
              <td>{moment(bonus.bonus_time).format("DD.MM.YY  HH:mm")}</td>
              <td>{BonusType[bonus.bonus_type]}</td>
              {bonus.bonus_type == 13 ? (
                <td>
                  {bonus.win_count}@
                  {currencyFormatter(bonus.win_amount.toFixed(rocketmanTransport.noOfDecimals))}{" "}
                  <Currency>{bonus.currency_code}</Currency>
                </td>
              ) : (
                <td>
                  {currencyFormatter(bonus.win_amount.toFixed(rocketmanTransport.noOfDecimals))}{" "}
                  <Currency>{bonus.currency_code}</Currency>
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
